@import "../../style/variables.scss";

.visList {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 2rem;
    grid-auto-rows: minmax(calc((100vh - 3rem) / 7), auto);
    flex-grow: 1;
}

.visItem {
    width: 100%;
    background: $card-bg;
    border-radius: $card-radius;
    padding: 1rem;

    grid-column: 1 / 9;
    grid-row: 1;
}

.visComponents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

.visChartArea {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
}
