@import "./style/variables.scss";

.container {
    width: 100vw;
    padding: 0 2rem 2rem 2rem;
    margin: 0 auto;
    color: $grey8;
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($grey2, 0.5);
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &[data-visible="false"] {
        pointer-events: none;
        opacity: 0;
    }
}

.body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2rem;
}
