$grey1: #20232b;
$grey2: #2b2e37;
$grey3: #37404a;

$grey6: #8c95a2;
$grey7: #9fa0a4;
$grey8: #e7e9ec;

$cyan: #00d2c3;
$cyan2: #6bb4af;
$blue-medium: #253c4a;
$blue-dark: #142031;

$red: #e47d80;
$orange: #e6be56;
$green: #9bd255;

$pri: $cyan;
$sec: $orange;

$card-bg: rgba(white, 0.1);
$card-radius: 4px;

$font-body: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

$shadow-drop: 0 3px 45px rgba(0, 0, 0, 0.15);

@mixin fullWidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
