@import "../../style/variables.scss";

.settingsPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 240px;
    margin-right: 2rem;
    background: $card-bg;
    border-radius: $card-radius;
    padding: 2rem;
}

.logo {
    height: 36px;
    filter: invert(100%);
}

.settingsHeader {
    margin: 0;
}

.settingsControls {
    width: 100%;
}

.settingsControlItem {
    width: 100%;
    margin-top: 2rem;
}

.settingsControlLabel {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    font-weight: 500;
}

.dropdown {
    margin-top: 0rem;
    width: 100%;
    color: $grey2;
}

// -----

.policySelector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.policy {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.25rem 0.5rem;
    width: calc(50% - 0.125rem);
    margin-bottom: 0.25rem;
    border-radius: 2px;
    border: 1px solid $card-bg;

    &[data-selected="true"] {
        background: $card-bg;
    }

    &:hover {
        background: rgba(white, 0.05);
    }
}

.policyLabel {
    font-size: 0.75rem;
    font-weight: 500;
}

.policyDescription {
    font-size: 0.625rem;
    opacity: 0.8;
}

// ----

.toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background 0.2s;

    &[data-on="true"] {
        .toggleLabel {
            opacity: 1;
        }
    }
    &:hover {
        background: rgba(white, 0.03);
    }
}

.toggleLabel {
    font-size: 0.75rem;
    text-transform: uppercase;
    opacity: 0.8;
}

.toggleControl {
    height: 24px;
    width: 48px;
    background: $card-bg;
    border-radius: 12px;
    position: relative;
}

.toggleHandle {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.2s;
    &[data-on="true"] {
        left: calc(100% - 24px);
    }
}

// -----------

.numberFieldOuter {
    border-bottom: 1px solid white;
    padding: 0.25rem 0;
}

.numberFieldInputContainer {
    display: flex;
    flex-direction: row;
}

.numberFieldInput {
    flex-grow: 1;
    background: none;
    border: none;
    outline: none;
    color: $grey8;
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    font-family: $font-body;
}

.numberFieldConfirmButton {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: $grey8;

    transition: background 0.2s;
    &:hover {
        background: rgba(white, 0.03);
    }

    svg {
        width: 18px;
    }
}

.numberFieldValue {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    padding: 0;
    transition: background 0.2s;
    &:hover {
        background: rgba(white, 0.03);
    }
}
