@import "./variables.scss";

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: $font-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $grey2;
    background: linear-gradient($blue-medium, $blue-dark);
    background-attachment: fixed;
}

body * {
    box-sizing: border-box;
}

h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1rem;
    text-transform: uppercase;
}

h3 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
}
