@import "../../style/variables.scss";

.barChart {
    width: 100%;
    height: 100%;
}

.bar {
    stroke: none;
    fill: $pri;
    opacity: 0.6;

    &[data-secondary="true"] {
        fill: $red;
    }
    &[data-hovered="true"] {
        // fill: $pri;
        // &[data-secondary="true"] {
        //     fill: $sec;
        // }
        opacity: 1;
    }

    &[data-positive="true"] {
        fill: $red;
    }

    &[data-positive="false"] {
        fill: $green;
    }
}

.areaData {
    fill: $pri;
    opacity: 0.6;
    &[data-secondary="true"] {
        fill: $red;
    }
}

.areaDots {
    fill: white;
}

.areaHighlightBar {
    fill: white;
    opacity: 0.3;
}

.historyLine {
    fill: none;
    stroke: $pri;
    stroke-width: 2;
    &[data-secondary="true"] {
        stroke: $red;
    }
}

.axisLine {
    stroke: $grey7;
}

.zeroLine {
    stroke: white;
    opacity: 0.2;
}

.axisTickYText,
.axisTickXText {
    font-size: 0.75rem;
    fill: $grey6;
    font-weight: 500;
}

.axisTickYText {
    text-anchor: end;
}

.axisTickXLine {
    stroke: $grey6;
}

.gridLineY {
    stroke: white;
    opacity: 0.4;
    stroke-dasharray: 4 4;
}

.dateHoverRect {
    fill: rgba($grey1, 0.7);
}

.dateHoverText {
    fill: white;
    text-anchor: middle;
    font-size: 0.875rem;
    font-weight: 500;
}

.hoverOverlay {
    fill: transparent;
    cursor: col-resize;
}

.breakpointLine {
    stroke-width: 1px;
    stroke-dasharray: 8 4;
}

.policyHighlight {
    fill: white;
    opacity: 0.1;
}

.historicalHighlight {
    fill: $grey6;
    opacity: 0.1;
}

.historicalLine {
    stroke: white;
    opacity: 0.4;
    stroke-width: 3;
    stroke-dasharray: 8 4;
}

.todayLabel,
.policyHighlightLabel {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    fill: $grey8;
}
.todayLabel {
    text-anchor: end;
}
