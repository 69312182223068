@import "../../style/variables.scss";

.visFactoid {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
}

.visHeading {
    margin: 0;
    margin-right: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    white-space: nowrap;
}

.visNumbers {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.visValue {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    text-transform: none;
}

.visDate {
    position: relative;
    font-size: 0.75rem;
}
